<style>
	/* 视图全屏 */
	html,
	body,
	#app {
		/* min-width: 1280px; */
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		background: linear-gradient(150deg, #2c6dd2 50%, #5eb361);
	}
	::-webkit-scrollbar {
		width: 0px;
	}
</style>
<template>
	<div id="app">
		<!-- 嵌套路由 -->
		<router-view :key="router_view_key" />
	</div>
</template>
<script>
	export default {
		name: "App",
		computed: {
			//使不同url路由到相同组件,也可重新渲染(key不同)
			router_view_key() {
				return this.$route.path;
			},
		},
	};
</script>

<script language="Javascript">
    //禁用页面的ctrl功能，来禁止ctrl+s保存功能
    window.addEventListener('keydown', function (e) {
        if(e.keyCode == 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)){
            e.preventDefault();
        }
    })
</script>