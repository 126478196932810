import Vue from 'vue'
import VueRouter from 'vue-router'
import request from "../plugins/axios.js";

Vue.use(VueRouter)

const routes = [
  {//登录
    name: "login",
    path: "/login",
    component: () => import('../views/login.vue')
  },
  {//商密产品
    name: "product",
    path: "/product",
    component: () => import('../views/user/product.vue')
  },
  {//商密产品
    name: "product_detail",
    path: "/product_detail0/:stype",
    component: () => import('../views/user/product_detail0.vue')
  },
  {//商密产品
    name: "product_detail",
    path: "/product_detail1/:type",
    component: () => import('../views/user/product_detail1.vue')
  },
  {//政策法规
    name: "book",
    path: "/book",
    component: () => import('../views/user/book.vue')
  }, 

  {//展示pdf
    name: "show_pdf",
    path: "/show_pdf",
    component: () => import('../views/user/show_pdf.vue')
  },
  {//随机练习
    name: "test",
    path: "/test",
    component: () => import('../views/user/test.vue')
  },
  {//选择试卷类型，公布成绩
    name: "paper",
    path: "/paper",
    component: () => import('../views/user/paper.vue')
  },
  {//试卷答题
    name: "exam",
    path: "/exam/:id",
    component: () => import('../views/user/exam.vue')
  }
]

const router = new VueRouter({
  mode: 'history', //去掉url中的#
  base: process.env.BASE_URL,
  routes
})

// 全局前置路由守卫---初始化的时候被调用、每次路由切换的时候被调用
router.beforeEach((to, from, next) => {
  let token = window.localStorage.getItem("token");
  //即判断用户是否进入了需要鉴权的路由下
  next()
})


export default router